<template>
  <div>
    <div class="mass-action-settings">
      <el-form
        size="small"
        ref="editForm"
        label-position="top"
      >
        <el-form-item label="Год" prop="year">
          <el-date-picker
            v-model="actionData.year"
            type="year"
            value-format="yyyy"
          >
          </el-date-picker>
        </el-form-item>

      </el-form>


      <el-popconfirm
        style=""
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите изменить год?"
        @confirm="doAction"
      >
        <el-button
          slot="reference"
          size="small"
          type="primary"
          :disabled="actionButtonDisabled"
        >
          Изменить
        </el-button>

      </el-popconfirm>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
    ></mass-action-result>
  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import MassActionResult from "@/components/massActions/MassActionResult.vue";
import CardsStageNegotiationSelect from "@/components/filters/cards/CardsStageNegotiationSelect.vue";

export default {
  name: "card-update-action",
  mixins: [ActionTemplateMixin],
  components: {CardsStageNegotiationSelect, MassActionResult},

  computed: {
    actionButtonDisabled() {
      return !this.actionData.year;
    }
  },

  data() {
    return {
      actionData: {
        year: null,
      }
    }
  },
  methods: {},
}
</script>
